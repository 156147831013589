header {
  background-color: #250b28;
  color: #fff;
  position: sticky;
  left: auto;
  top: 0%;
  z-index: 100;
}

.logo-banner{
  max-width: 260px;
}

.header-block {
  display: flex;
  margin-right: 40px;
  margin-left: 40px;
  justify-content: space-between;
  align-items: center;
}

.header-nav {
  display: flex;
  align-items: center;
}

.nav-link {
  font-size: 18px;
  padding: 27px 12px;
  cursor: pointer;
  font-weight: bold;
  color: #D83AEB;
  line-height: 26px;
  text-decoration: none;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.logo-link {
  display: flex;
  align-content: center;
}

.nav-link:hover {
  color: #fff;
  box-shadow: inset 0 -4px 0 0 #5c485e;
}

.button-group {
  display: flex;
  flex-direction: row;
}

.padding-right-8 {
  padding-right: 8px;
}

.buy-now-button {
  background-color: #d83aeb;
  color: #fff;
  border-style: none;
  padding: 8px 12px;
  font-size: 16px;
  border: 1px solid #d83aeb;
  height: 100%;
}

.buy-now-button:hover {
  background-color: #e26bf0;
}

.connect-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #250b28;
  border: 2px solid #5c485e;
  color: #fff;
  padding: 6px 16px;
  margin-left: 16px;
  line-height: 24px;
  font-weight: 800;
  font-size: 16px;
}

.connect-button:hover {
  border-color: #fff;
  background-color: #5c485e;
}

.toggle-menu-icon {
  display: none;
  color: #fff;
}

@media only screen and (max-width: 991px) {
  .header-block {
    display: block;
  }

  .header-nav {
    display: none;
  }
  .button-group {
    display: none;
  }

  .logo-block {
    width: 100%;
    display: flex;
    justify-content: center;
    position: relative;
  }

  .toggle-menu-icon {
    display: inline;
    cursor: pointer;
    position: absolute;
    right: 0;
    top: 37%;
  }

  .logo-link {
    padding: 16px 0;
  }

  .nav-link {
    width: 100%;
  }

  .nav-link:hover {
    box-shadow: none;
  }

  .visible {
    display: flex;
    flex-direction: column;
    transition: display 0.25s ease-in;
  }

  .header-block {
    margin-right: 3%;
    margin-left: 3%;
  }
}

@media only screen and (max-width: 360px) {
  .header-block {
    margin-right: 1%;
    margin-left: 1%;
  }

  .logo-block {
    justify-content: flex-start;
    position: relative;
  }

  .logo-banner{
    width: 80%;
  }
}