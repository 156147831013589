@font-face {
  font-family: Inconsolata;
  src: url("./assets/fonts/Inconsolata/Inconsolata-ExtraLight.ttf");
}
@font-face {
  font-family: Inconsolata;
  font-weight: 400;
  src: url("./assets/fonts/Inconsolata/Inconsolata-Regular.ttf");
}
@font-face {
  font-family: Inconsolata;
  font-weight: 700;
  src: url("./assets/fonts/Inconsolata/Inconsolata-Bold.ttf");
}
@font-face {
  font-family: Inconsolata;
  font-weight: 800;
  src: url("./assets/fonts/Inconsolata/Inconsolata-ExtraBold.ttf");
}
@font-face {
  font-family: Inconsolata;
  font-weight: 900;
  src: url("./assets/fonts/Inconsolata/Inconsolata-Black.ttf");
}
@font-face {
  font-family: PressStart2P;
  src: url("./assets/fonts/PressStart2P-Regular.ttf");
}

*{
  line-height: 1.5 !important;
}

body {
  margin: 0;
  font-family: Inconsolata, sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

button {
  font-family: Inconsolata, sans-serif;
  font-weight: 800;
}

p {
  color: #5c485e;
  font-weight: 400;
  font-size: 18px;
  text-align: center;
  line-height: 26px;
}

h1 {
  font-size: 40px;
  color: #250b28;
  font-weight: 900;
}

h2 {
  font-size: 28px;
  line-height: 38px;
  color: #250b28;
  font-weight: 900;
}

h3 {
  font-size: 22px;
  line-height: 30px;
  font-weight: 900;
}

.btn {
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  cursor: pointer;
}

* {
  box-sizing: border-box !important;
}
.buttons-block {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.container {
  max-width: 1140px;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: 15%;
  margin-left: 15%;
  text-align: center;
}

@media only screen and (min-width: 1200px) {
  .container {
    max-width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: 15%;
    margin-left: 15%;
  }
}
@media only screen and (min-width: 768px) {
  .block-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0 5%;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.stepper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
