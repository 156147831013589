.about-block {
  padding-top: 70px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.library-link {
  color: #d83aeb;
  font-weight: 700;
  text-decoration: none;
}
.new-line{
  white-space: pre-line;

}
.library-link:hover {
  text-decoration: underline;
}

.vault-image {
  height: 240px;
  width: 302px;
}

@media only screen and (max-width: 1199px) {
  .vault-image {
    height: 192px;
    width: 242px;
  }
}

.competition {
  padding-top: 50px;
}

.competition-image {
  width: 500px;
  height: 500px;
  text-align: center;
}

@media only screen and (max-width: 600px) {
  .competition-image {
    width: 300px;
    height: 300px;
  }
}

@media only screen and (max-width: 400px) {
  .competition-image {
    width: 200px;
    height: 200px;
  }
}