.footer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 120px 0;
}

.footer-navigation {
  width: 90px;
  display: flex;
  justify-content: space-between;
}

.roundbuttton {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 45px;
  height: 45px;
  padding: 0px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 20px;
  background-color: rgba(216, 58, 235, 0.12);
}


.roundbuttton.margin {
    margin-right: 10px;
}

.btnholderinner{
  display: flex
}