

.gallery .container .row {
  
  margin: 2rem;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr;
}
@media only screen and (min-width: 320px) and (max-width: 667px) {
  .gallery .container .row {
  
    margin: 2rem;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
  }
}
.cell {
  color: white;
  font-size: 3rem;
  text-align: center;
  padding: 4rem;
}
.cell-1 {
  background: deepskyblue;
}
.cell-2 {
  background: orangered;
}
.cell-3 {
  background: royalblue;
}
.cell-4 {
  background: gold;
}
.cell-5 {
  background: blueviolet;
}
.cell-6 {
  background: limegreen;
}
.cell-7 {
  background: coral;
}
.cell-8 {
  background: lightseagreen;
}
.cell-9 {
  background: maroon;
}

img{
 padding: 10px;

} 

/* .row{
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));

  align-items: center;
  margin-left: 1rem;
  margin-right: 1rem;
  grid-gap: 1rem;
} */

/* .imageRow {
  display: grid;
  grid-template-columns : 1 fr 1fr 1fr;
} */